import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import chargifyUI from "../../images/chargify-ui.png";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Process"
      />

      <section className="container mx-auto p-6 mt-40 mb-20">
        <a href="/work" className="font-accent antialiased italic inline-block bg-green-800 text-white text-sm py-2 px-6 rounded-full mb-12">view all work</a>
        <h1 className="font-heading antialiased uppercase text-white text-6xl leading-none mb-6 w-3/4">
          Redefining the Subscription <br />Management Experience
        </h1>
        <p className="text-white text-2xl leading-relaxed">Even when software powers tens thousands of merchants and billions in transactions each year doesn’t mean it doesn't have any challenges. Here’s how we took a step toward making Chargify relationship-focused for our customers.</p>
      </section>

      <section className="container mx-auto flex justify-between items-center p-6 mb-20">
        <div className="flex-1">

          <p className="font-heading antialiased leading-snug text-white font-medium text-4xl mb-6 pr-20">
            Redesigning the subscription management experience
          </p>

          <p className="leading-loose text-white pr-20">
            Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo.
          </p>

          <p className ="mt-10 font-accent italic text-sm text-white">
            <a href="/process" className="inline-block bg-green-800 text-white py-4 px-8 rounded-full">view case study</a>
          </p>
        </div>
        <div className="flex-1 overflow-x-hidden -mr-6">
          <img
            alt="Cat and human sitting on a couch"
            className="block rounded"
            src={chargifyUI}
          />
        </div>
      </section>

      <section className="container mx-auto flex justify-between items-center p-6 mb-20">
        <div className="flex-1">

          <p className="antialiased leading-snug text-white font-medium text-2xl mb-6 pr-20">
            Redesigning the subscription management experience
          </p>

          <p className="leading-loose text-white pr-20">
            Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo.
          </p>

          <p className ="mt-10 font-accent italic text-sm text-white">
            <a href="/process" className="inline-block bg-green-800 text-white py-4 px-8 rounded-full">view case study</a>
          </p>
        </div>
        <div className="flex-1 overflow-x-hidden -mr-6">
          <img
            alt="Cat and human sitting on a couch"
            className="block"
            src={chargifyUI}
          />
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
